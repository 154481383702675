import React, { useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { useNavigate } from "react-router-dom";

const CreateOrganizationPage = () => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState(null);
  const navigate = useNavigate();

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name,
      type,
      address,
      website,
      phone,
      logo,
    };

    // Check if any previous data is already there or not
    const sessionOrganizationData = JSON.parse(
      sessionStorage.getItem("organizationData")
    );

    let updatedFormData;
    if (
      sessionOrganizationData !== null &&
      !Array.isArray(sessionOrganizationData) &&
      typeof sessionOrganizationData === "object"
    ) {
      updatedFormData = [formData, sessionOrganizationData];
    } else if (Array.isArray(sessionOrganizationData)) {
      updatedFormData = [...sessionOrganizationData, formData];
      console.log(updatedFormData);
    } else {
      updatedFormData = [formData];
    }

    // Save the form data to session storage
    sessionStorage.setItem("organizationData", JSON.stringify(updatedFormData));

    // Log the form data
    console.log("Form Data as JSON:", JSON.stringify(updatedFormData, null, 2));

    // Navigate to homescreen
    navigate("/customers");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <div className="container mx-auto">
        <h1 className="text-3xl font-medium text-customBlue mb-8 ml-[-2%]">
          Create Organization
        </h1>
        <form
          className="bg-white shadow-md rounded-lg p-8 mt-[-1%] flex mb-12"
          onSubmit={handleSubmit}
        >
          <div className="w-5/6 pr-8">
            <div className="mb-4">
              <label className="block text-gray-700">Organization Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Organization Type:</label>
              <input
                type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Address:</label>
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-full border p-2 rounded mt-1"
                rows="3"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Website:</label>
              <input
                type="text"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Phone Number:</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-blue-950 text-white text-xl py-2 px-8 rounded-lg mt-12"
              >
                Create
              </button>
            </div>
          </div>
          <div className="w-1/6 flex flex-col items-center">
            <div className="mb-4">
              {logo ? (
                <img
                  src={logo}
                  alt="Logo"
                  className="w-32 h-32 rounded-full object-cover"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-500">Upload Logo</span>
                </div>
              )}
            </div>
            <label
              htmlFor="logo-upload"
              className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
            >
              Upload Logo
            </label>
            <input
              id="logo-upload"
              type="file"
              accept="image/*"
              onChange={handleLogoUpload}
              className="hidden"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOrganizationPage;

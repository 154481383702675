// src/components/DashboardPage.js

import React from "react";
import organisationIcon from "../../images/organisations.png";
import trainingCourseIcon from "../../images/training-course-icon.png";
import supportingMaterialsIcon from "../../images/supporting-materials-icon.png";
import financeIcon from "../../images/finance-icon.png";
import { useEffect, useState } from "react";
import {
  customerList,
  trainingCourses,
  upcomingWebinars,
} from "../../apiFunction/ApiFunctions";
import HeaderComponent from "./shared/HeaderComponent";
import { useNavigate } from "react-router-dom";

const Homescreen = () => {
  const [courseList, setCourseList] = useState([]);
  const [webinars, setWebinars] = useState([]);
  const navigate = useNavigate();
  const overdueCustomerCount = customerList("inactive", 1);
  const sessionUser = JSON.parse(
    sessionStorage.getItem("user")
  );

  function handleNavigation(path) {
    navigate(path);
  }

  useEffect(() => {
    const sessionTrainingVideo = JSON.parse(
      sessionStorage.getItem("trainingVideo")
    );
    const trainingCoursesArr = trainingCourses(); // Assuming this is a function that returns an array

    // Only add sessionTrainingVideo if it is valid
    if (sessionTrainingVideo) {
      console.log([...sessionTrainingVideo, ...trainingCoursesArr]);

      setCourseList([...sessionTrainingVideo, ...trainingCoursesArr].slice(0, 3));
    } else {
      setCourseList(trainingCoursesArr.slice(0, 3));
    }
    setWebinars(upcomingWebinars());
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent name={sessionUser ? sessionUser.firstname : 'Mery'} image={sessionUser.image} />

      <main className="w-[75%] p-6 flex justify-center items-start, ml-[15%] mt-[-8%]">
        <div className="flex w-full max-w-8xl">
          {/* Main Content Section */}
          <div className="flex-grow space-y-6">
            {/* Statistics Section */}
            <div className="grid grid-cols-3 gap-4 w-full mb-6">
              <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
                <p className="text-xl text-blue-950">This Weeks Revenue</p>
                <p className="bg-green-500 py-2 px-4 text-lg text-blue-950 rounded-2xl justify-center mt-[5%]">
                  £ 25,000
                </p>
              </div>
              <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
                <p className="text-xl text-blue-950">This Months Revenue</p>
                <p className="bg-green-500 py-2 px-4 text-lg text-blue-950 rounded-2xl justify-center mt-[5%]">
                  £ 125,000
                </p>
              </div>
              <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
                <p className="text-xl text-blue-950">Cancellations</p>
                <p className="bg-red-500 py-2 px-4 text-lg rounded-2xl justify-center mt-[5%]">
                  3
                </p>
              </div>
              <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
                <p className="text-xl text-blue-950">New Monthly Customers</p>
                <p className="bg-green-500 py-2 px-4 text-lg text-blue-950 rounded-2xl justify-center mt-[5%]">
                  36
                </p>
              </div>
              <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
                <p className="text-xl text-blue-950">Average Feedback Score</p>
                <p className="bg-green-500 py-2 px-4 text-lg text-blue-950 rounded-2xl justify-center mt-[5%]">
                  96%
                </p>
              </div>
              <button
                className="bg-white rounded-2xl shadow-lg p-6 text-center"
                onClick={() =>
                  handleNavigation("/expired-subscription-customers")
                }
              >
                <p className="text-xl text-blue-950">Overdue Customers</p>
                <p className="bg-red-500 py-2 px-4 text-lg rounded-2xl justify-center mt-[5%]">
                  {overdueCustomerCount}
                </p>
              </button>
            </div>

            {/* Training Courses and Upcoming Webinars Section */}
            <div className="flex space-x-6 mt-[5%]">
              <div className="flex-grow space-y-6">
                {/* Training Courses Section */}
                <h2 className="text-3xl text-blue-950 font-semibold mb-4 ml-[-3%]">
                  Training Courses
                </h2>
                <section className="bg-white rounded-lg shadow-lg p-6 mt-[-1%]">
                  <div className="space-y-4">
                    {courseList &&
                      courseList.map((item) => (
                        <div
                          key={item.title}
                          className="bg-gray-200 rounded-2xl p-4 flex justify-between items-center"
                        >
                          <div className="flex items-center space-x-4">
                            <img
                              src={item.image}
                              alt="Course"
                              className="w-16 h-16 rounded-lg"
                            />
                            <p className="text-black-800">{item.title}</p>
                          </div>
                          <button
                            type="submit"
                            className="bg-blue-100 text-blue-700 py-2 px-4 rounded-lg"
                            onClick={() =>
                              handleNavigation("/create-training-video")
                            }
                          >
                            Create
                          </button>
                        </div>
                      ))}
                  </div>
                </section>

                {/* Upcoming Live Webinars Section */}
                <h2 className="text-3xl text-blue-950 font-semibold ml-[-3%]">
                  Upcoming Live Webinars
                </h2>
                <section className="bg-gray-200 rounded-lg shadow-lg p-6 mt-[1%]">
                  <div className="space-y">
                    {webinars &&
                      webinars.map((item) => (
                        <div
                          key={item.title}
                          className="flex justify-between items-center"
                        >
                          <div className="flex items-center space-x-2">
                            <p className="text-black-800">{item.title}</p>
                          </div>
                          <p className=" text-blue-700 py-2 px-4 rounded-lg">
                            {item.date}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div className="flex justify-center items-center mt-4">
                    <button
                      type="submit"
                      className="bg-white border border-black text-black py-1 px-6 rounded-3xl font-medium w-32 mr-4"
                      onClick={() => handleNavigation("/create-webinar")}
                    >
                      Create
                    </button>

                    <button
                      type="submit"
                      className="bg-white border border-black text-black py-1 px-6 rounded-3xl font-medium w-32"
                      onClick={() =>
                        handleNavigation("/upcoming-training-webinars")
                      }
                    >
                      View All
                    </button>
                  </div>
                </section>
              </div>

              {/* Sidebar Section */}
              <div className="flex flex-col space-y-20 space-y-6 w-48 md-20 mt-[6%]">
                <button
                  className="bg-white rounded-2xl shadow-lg p-6 flex items-center space-x-4 hover:bg-gray-100 flex-shrink-0 w-full h-20"
                  onClick={() => handleNavigation("/customers")}
                >
                  <img
                    src={organisationIcon}
                    alt="Customers"
                    className="w-10"
                  />
                  <h2 className="text-lg text-gray-700">Customers</h2>
                </button>
                <button
                  className="bg-white rounded-2xl shadow-lg p-6 flex items-center space-x-4 hover:bg-gray-100 flex-shrink-0 w-full h-20"
                  onClick={() => handleNavigation("/training-courses")}
                >
                  <img
                    src={trainingCourseIcon}
                    alt="Training Courses"
                    className="w-10"
                  />
                  <h2 className="text-lg text-gray-700">Training Courses</h2>
                </button>
                <button
                  className="bg-white rounded-2xl shadow-lg p-6 flex items-center space-x-4 hover:bg-gray-100 flex-shrink-0 w-full h-20"
                  onClick={() => handleNavigation("/supporting-materials")}
                >
                  <img
                    src={supportingMaterialsIcon}
                    alt="Training Courses"
                    className="w-10"
                  />
                  <h2 className="text-lg text-gray-700">
                    Supporting Materials
                  </h2>
                </button>
                <button
                  className="bg-white rounded-2xl shadow-lg p-6 flex items-center space-x-4 hover:bg-gray-100 flex-shrink-0 w-full h-20"
                  onClick={() => handleNavigation("/customers")}
                >
                  <img
                    src={financeIcon}
                    alt="Training Courses"
                    className="w-10"
                  />
                  <h2 className="text-lg text-gray-700">Finance</h2>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Homescreen;

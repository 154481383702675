import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./components/admin/LoginPage";
import LoginForm from "./components/admin/LoginForm";
import "./index.css"; // Make sure to import the index.css where Tailwind directives are added
import Homescreen from "./components/admin/Homescreen";
import ActiveTrainingCourses from "./components/user/ActiveTrainingCourses";
import LiveTrainingWebinars from "./components/user/UpcomingTrainings";
import CompletedCourses from "./components/user/CompletedTrainings";
import SuccessPage from "./components/user/Success";
import RecordedWebinars from "./components/user/RecordedWebinars";
import TrainingModules from "./components/user/CourseDetails";
import MediaPlayer from "./components/user/MediaPlayer";
import SupportingMaterials from "./components/user/SupportingMaterials";
import DementiaTrainingModules from "./components/user/TrainingModules";
import CustomerListPage from "./components/admin/CustomerListPage";
import CreateWebinarPage from "./components/admin/CreateWebinarPage";
import CreateTrainingVidePage from "./components/admin/CreateTrainingVideoPage";
import TrainingCoursesPage from "./components/admin/TrainingCoursesPage";
import SupportingMaterialsPage from "./components/admin/SupportingMaterialsPage";
import ExpiredSubscriptionCustomerPage from "./components/admin/ExpiredSubscriptionCustomerPage";
import OrganizationDashboardPage from "./components/admin/OrganizationDashboardPage";
import UserListPage from "./components/admin/UserListPage";
import CreateUserPage from "./components/admin/CreateUserPage";
import CreateOrganizationPage from "./components/admin/CreateOrganizationPage";
import UserProfilePage from "./components/admin/UserProfilePage";
import SubscriptionPage from "./components/admin/SubscriptionPage";
import UploadSupportingMaterialPage from "./components/admin/UploadSupportingMaterialPage";
import WebinarRegistrationListPage from "./components/admin/WebinarRegistrationListPage";
import UpcomingTrainingWebinarPage from "./components/admin/UpcomingTrainingWebinarPage";
import CourseDetailsPage from "./components/admin/CourseDetailsPage";
import Dashboard from "./components/user/Dashboard";
import PrivateAdminComponent from "./components/admin/PrivateAdminComponent";
import PrivateUserComponent from "./components/user/PrivateUserComponent";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/mediaplayer" element={<MediaPlayer />} />

        <Route element={<PrivateUserComponent />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/activetrainings" element={<ActiveTrainingCourses />} />
          <Route path="/upcomingtrainings" element={<LiveTrainingWebinars />} />
          <Route path="/completedtrainings" element={<CompletedCourses />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/recordedwebinars" element={<RecordedWebinars />} />
          <Route path="/coursedetails" element={<TrainingModules />} />
          <Route
            path="/supportingmaterials"
            element={<SupportingMaterials />}
          />
          <Route
            path="/trainingmodules"
            element={<DementiaTrainingModules />}
          />
        </Route>
        <Route element={<PrivateAdminComponent />}>
          <Route path="/homescreen" element={<Homescreen />} />
          <Route path="/customers" element={<CustomerListPage />} />
          <Route
            path="/expired-subscription-customers"
            element={<ExpiredSubscriptionCustomerPage />}
          />
          <Route path="/create-webinar" element={<CreateWebinarPage />} />
          <Route
            path="/create-training-video"
            element={<CreateTrainingVidePage />}
          />
          <Route path="/training-courses" element={<TrainingCoursesPage />} />
          <Route
            path="/supporting-materials"
            element={<SupportingMaterialsPage />}
          />
          <Route
            path="/organization-dashboard"
            element={<OrganizationDashboardPage />}
          />
          <Route path="/user-list" element={<UserListPage />} />
          <Route path="/create-user" element={<CreateUserPage />} />
          <Route
            path="/create-organization"
            element={<CreateOrganizationPage />}
          />
          <Route path="/user-profile" element={<UserProfilePage />} />
          <Route path="/subscriptions" element={<SubscriptionPage />} />
          <Route
            path="/upload-supporting-materials"
            element={<UploadSupportingMaterialPage />}
          />
          <Route
            path="/webinar-registration-list"
            element={<WebinarRegistrationListPage />}
          />
          <Route
            path="/upcoming-training-webinars"
            element={<UpcomingTrainingWebinarPage />}
          />
          <Route path="/course-details" element={<CourseDetailsPage />} />
        </Route>
        {/* Other routes */}
      </Routes>
    </Router>
  );
};

export default App;

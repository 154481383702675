import React from "react";
import trainingImage from "../../images/training-image.png"; // Replace with actual path to image
import logo from "../../images/dementia-logo.png"; // Replace with actual path to logo

const LoginPage = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="flex flex-col sm:flex-row bg-white rounded-lg shadow-lg overflow-hidden w-full h-full">
        <div
          className="flex-1 flex items-center justify-center bg-gray-200 h-auto p-10 rounded-lg m-[40px]"
        >
          <img
            src={trainingImage}
            alt="Training"
            className="w-full h-auto max-w-md"
          />
        </div>
        <div className="flex-1 flex flex-col items-center justify-center p-20 text-center h-full">
          <img
            src={logo}
            alt="6D Dementia Training"
            className="w-80 h-auto mb-8"
          />
          <p
            className="text-2xl mb-10 text-customBlue hover:text-blue-80"
          >
            Welcome to 6D
            <br />
            Dementia Training
          </p>
          <a href="./login" className="block mt-4">
            <button className="bg-blue-900 text-white py-3 px-8 rounded-lg hover:bg-blue-700 text-xl mt-24">
              Login
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

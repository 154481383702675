// import organisationIcon from "../images/organisations.png"; // Adjust the path as needed
import courseIcon1 from "../images/Image(2).png"; // Adjust the path as needed
import courseIcon2 from "../images/Image(3).png"; // Adjust the path as needed
import courseIcon3 from "../images/Image(4).png"; // Adjust the path as needed
import courseIcon4 from "../images/Image(5).png"; // Adjust the path as needed
import customerIcon1 from "../images/Loughview care home.png"; // Adjust the path as needed
import customerIcon2 from "../images/Greenfield NHS trust.png"; // Adjust the path as needed
import customerIcon3 from "../images/Lakeside care home.png"; // Adjust the path as needed
import customerIcon4 from "../images/Sunnyside care home.png"; // Adjust the path as needed
import user1 from "../images/user1.png";
import user2 from "../images/user2.png";
import user3 from "../images/user3.png";
import user4 from "../images/user4.png";
import user5 from "../images/user5.png";
import user6 from "../images/user6.png";
import ecd from "../images/ECD.png";
import pain from "../images/Pain.png";
import ucb from "../images/UCB.png";
// import usersIcon from "../images/users.png"; // Adjust the path as needed

export function userInfo(role = "", length = 3) {
  const users = [
    {
      name: "Test User",
      firstname: "Test",
      lastname: "User",
      email: "test@example.com",
      password: "123456",
      role: "admin",
      designation: "Manager",
      image: user1,
    },
    {
      name: "Brendan Crossey",
      firstname: "Brendan",
      lastname: "Crossey",
      email: "brendan@example.com",
      password: "123456",
      role: "admin",
      designation: "Owner",
      image: user3,
    },
    {
      name: "Merry Mchom",
      firstname: "Merry",
      lastname: "Mchom",
      email: "merry@example.com",
      password: "123456",
      role: "user",
      designation: "Nurse",
      image: user2,
    },
    {
      name: "Martin Jhonston",
      firstname: "Martin",
      lastname: "Jhonston",
      email: "martin@example.com",
      password: "123456",
      role: "admin",
      designation: "Manager",
      image: user4,
    },
    {
      name: "Sarah Jhonson",
      firstname: "Sarah",
      lastname: "Jhonson",
      email: "sarah@example.com",
      password: "123456",
      role: "admin",
      designation: "Doctor",
      image: user5,
    },
    {
      name: "Fred Smyth",
      firstname: "Fred",
      lastname: "Smyth",
      email: "fred@example.com",
      password: "123456",
      role: "admin",
      designation: "Manager",
      image: user6,
    },
    {
      name: "John Doe",
      firstname: "John",
      lastname: "Doe",
      email: "john@example.com",
      password: "123456",
      role: "user",
      designation: "Nurse",
      image: user1,
    },
    {
      name: "James Smith",
      firstname: "James",
      lastname: "Smith",
      email: "james@example.com",
      password: "123456",
      role: "user",
      designation: "Demnetia Nurse",
      image: user2,
    },
    {
      name: "Sanjay Das",
      firstname: "Sanjay",
      lastname: "Das",
      email: "sanjay@example.com",
      password: "123456",
      role: "user",
      designation: "Nurse",
      image: user3,
    },
    {
      name: "Dhiraj Bharadwaj",
      firstname: "Dhiraj",
      lastname: "Bharadwaj",
      email: "dhiraj@example.com",
      password: "123456",
      role: "user",
      designation: "Doctor",
      image: user4,
    },
    {
      name: "Aurobinda De",
      firstname: "Aurobinda",
      lastname: "De",
      email: "ari@example.com",
      password: "123456",
      role: "user",
      designation: "Doctor",
      image: user5,
    },
  ];
  if (role && role === "admin") {
    return users.filter((user) => user.role === "admin").slice(0, length);
  } else if (role && role === "user") {
    return users.filter((user) => user.role === "user").slice(0, length);
  }
  return users;
}

export function trainingCourses() {
  const courses = [
    {
      id: 1,
      title: "6D Dementia Training for Care Staff",
      description: [
        "Understanding dementia and changes in behaviour",
        "Responding to changes in behaviour to reduce distress",
        "Tools to help understand changes in behaviour: looking for patterns",
        "The experience of the person with dementia living in a care home",
        "The challenges experienced by care staff in a care home, balancing the many demands on your time",
        "Meeting the needs of the resident with dementia and supporting their family",
        "Case examples",
        "Psychological wellbeing for care staff",
      ],
      image: courseIcon1,
      plays: 187,
    },
    {
      id: 2,
      title: "Dementia Training for Family Carers",
      description: [
        "Understanding dementia and changes in behaviour",
        "Responding to changes in behaviour to reduce distress",
        "Tools to help understand changes in behaviour: looking for patterns",
        "Case Examples",
        "Challenges of caring for someone who has dementia",
        "Psychological wellbeing for carers",
      ],
      image: courseIcon2,
      plays: 256,
    },
    {
      id: 3,
      title: "6D Dementia Team Training",
      description: [
        "Understanding dementia and changes in behaviour",
        "Looking at all the dimensions that are important to understand the person",
        "Seeing the perspective of the person with dementia and those who offer care and support. What do we do when these are different?",
        "Case studies",
        "How best to record behaviour to facilitate understanding",
        "The challenges experienced by staff and how to address these challenges",
      ],
      image: courseIcon3,
      plays: 312,
    },
    {
      id: 4,
      title: "6D Dementia Specialist Team Training",
      description: [
        "Implementing 6D Dementia within your team",
        "The processes that need to be in place to ensure good outcomes",
        "How to engage staff who will implement recommendations",
        "How to write recommendations to ensure that everyone who supports the person with dementia knows what to do to reduce potential distress",
      ],
      image: courseIcon4,
      plays: 198,
    },
  ];
  return courses;
}

export function completedCourse() {
  const completedCourses = [
    {
      title: "6D Training for Teams",
      date: "1st January 2023",
      score: "95%",
      modules: 6,
      image: courseIcon1,
    },
    {
      title: "6D Specialist Team Training",
      date: "15th February 2023",
      score: "88%",
      modules: 7,
      image: courseIcon2,
    },
    {
      title: "6D Advanced Team Training",
      date: "10th March 2023",
      score: "98%",
      modules: 6,
      image: courseIcon3,
    },
    {
      title: "6D Advanced Team Training 2",
      date: "10th March 2023",
      score: "98%",
      modules: 6,
      image: courseIcon4,
    },
  ];
  return completedCourses;
}

export function activeCourse() {
  const activeCourses = [
    {
      title: "6D Training for Teams",
      progress: 80,
      staffMembers: 10,
      renewalDate: "1st March 2025",
      status: "Subscribed",
    },
    {
      title: "6D Specialist Team Training",
      progress: 50,
      staffMembers: 24,
      renewalDate: "1st July 2024",
      status: "Expired",
    },
    {
      title: "6D Advanced Team Training",
      progress: 30,
      staffMembers: 12,
      renewalDate: "1st December 2024",
      status: "Subscribed",
    },
  ];
  return activeCourses;
}

export function award() {
  const awards = [
    { date: "7/5/23", title: "6D Training for Teams" },
    { date: "11/05/24", title: "6D Specialist Team Training" },
  ];
  return awards;
}

export function upcomingWebinars() {
  const webniars = [
    {
      title: "Effective Communication in Dementia",
      subtitle: "Improving communication skills in dementia care .",
      presentedBy: "Dr. Sarah Thompson",
      date: "November 13, 2024",
      time: "2:00 PM",
      staffRegistered: 56,
      image: ecd,
    },
    {
      title: "Pain",
      subtitle: "Pain in dementia patients.",
      presentedBy: "Dr. Emily Johnson",
      date: "November 24, 2024",
      time: "4:00 PM",
      staffRegistered: 32,
      image: pain,
    },
    {
      title: "Understanding Changes in Behaviour",
      subtitle: "Supporting the peson who is detressed",
      presentedBy: "Dr. Mark Roberts",
      date: "November 30, 2024",
      time: "10:00 AM",
      staffRegistered: 22,
      image: ucb,
    },
  ];
  return webniars;
}

export function customerList(status = "active", count = 0) {
  const customers = [
    {
      name: "Loughview Care Home",
      address: "123 Loughview, Lurgan",
      logo: customerIcon1, // Replace with actual image
      subscriptionStatus: "inactive",
    },
    {
      name: "Greenfield NHS Trust",
      address: "45 Green Street, Healthtown",
      logo: customerIcon2, // Replace with actual image
      subscriptionStatus: "active",
    },
    {
      name: "Lakeside Care Home",
      address: "789 Lake View Drive, Watertown",
      logo: customerIcon3, // Replace with actual image
      subscriptionStatus: "inactive",
    },
    {
      name: "Sunnyside Care Home",
      address: "123 Sunshine Lane, Pleasantville",
      logo: customerIcon4, // Replace with actual image
      subscriptionStatus: "inactive",
    },
    // {
    //   name: "Sanjay Kr Das",
    //   address: "123 Barrackpore, India",
    //   logo: "https://via.placeholder.com/150", // Replace with actual image
    //   subscriptionStatus: "active",
    // },
  ];

  if (status === "inactive") {
    const inactiveCustomers = customers.filter(
      (customer) => customer.subscriptionStatus === "inactive"
    );
    const totalInactiveCustomerCount = inactiveCustomers.length;
    return count ? totalInactiveCustomerCount : inactiveCustomers;
  }

  return customers;
}

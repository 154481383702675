// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { upcomingWebinars } from "../../apiFunction/ApiFunctions";
import { useNavigate } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";

const UpcomingTrainingWebinarPage = () => {
  const [webinars, setWebinars] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const sessionWebinarData = JSON.parse(
      sessionStorage.getItem("webinarData")
    );
    console.log(sessionWebinarData);
    const upcomingWebinarList = upcomingWebinars();

    // Only add sessionTrainingVideo if it is valid
    if (sessionWebinarData) {
      console.log([...upcomingWebinarList, ...sessionWebinarData]);
      setWebinars([...sessionWebinarData, ...upcomingWebinarList]);
    } else {
      setWebinars(upcomingWebinarList);
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4 mt-[-4%]">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">
              Upcoming Training Webinars
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
            {webinars.map((webinar, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-4 flex flex-col justify-between"
              >
                <div>
                  <img
                    src={webinar.image}
                    alt={webinar.title}
                    className="w-full h-48 object-cover rounded-lg mb-4"
                  />
                  <div className="text-center mb-6">
                    <h3
                      className="text-xl text-customBlue mb-2 cursor-pointer"
                      onClick={() =>
                        navigate("/webinar-registration-list", {
                          state: {
                            title: webinar.title,
                            subtitle: webinar.subtitle,
                            presentedBy: webinar.presentedBy,
                            date: webinar.date,
                            time: webinar.time,
                          },
                        })
                      }
                    >
                      {webinar.title}
                    </h3>
                    <p className="italic">{webinar.subtitle}</p>
                  </div>
                  <div className="space-y-2">
                    <p>Presented By: {webinar.presentedBy}</p>
                    <p>
                      Time: {webinar.date} - {webinar.time}
                    </p>
                    <p>
                      Staff Registered:{" "}
                      {webinar.staffRegistered ? webinar.staffRegistered : 0}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>

      <FooterComponent path="/homescreen" />
    </div>
  );
};

export default UpcomingTrainingWebinarPage;

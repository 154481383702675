// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { trainingCourses } from "../../apiFunction/ApiFunctions";
import { useNavigate } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";

const TrainingCoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const sessionTrainingVideo = JSON.parse(
      sessionStorage.getItem("trainingVideo")
    );
    console.log(sessionTrainingVideo);
    const trainingCoursesArr = trainingCourses();

    // Only add sessionTrainingVideo if it is valid
    if (sessionTrainingVideo) {
      console.log([...trainingCoursesArr, ...sessionTrainingVideo]);
      setCourses([...trainingCoursesArr, ...sessionTrainingVideo]);
    } else {
      setCourses(trainingCoursesArr);
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4 mt-[-4%]">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">
              Training Courses
            </h2>
            <button
              className="bg-yellow-500 text-white py-2 px-6 rounded-lg"
              onClick={() => navigate("/create-training-video")}
            >
              Create Training Course
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {courses.map((course, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-4 flex flex-col justify-between"
              >
                <div>
                  <img
                    src={course.image}
                    alt={course.title}
                    className="w-full h-48 object-cover rounded-lg mb-4"
                  />
                  <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
                  <ul className="list-disc pl-5 mb-4 text-gray-700">
                    {course.description.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <div className="flex items-center text-yellow-500">
                    {[...Array(5)].map((_, i) => (
                      <svg
                        key={i}
                        className={`w-5 h-5 ${
                          i < 5 ? "text-yellow-500" : "text-gray-300"
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927C9.469 1.777 10.53 1.777 10.951 2.927l1.286 3.968 4.168.325c1.199.093 1.719 1.56.83 2.378l-3.106 2.9.948 4.086c.27 1.157-.805 2.089-1.829 1.57L10 15.771l-3.398 1.788c-1.024.52-2.1-.413-1.83-1.57l.948-4.086-3.106-2.9c-.889-.818-.369-2.285.83-2.378l4.168-.325 1.286-3.968z" />
                      </svg>
                    ))}
                  </div>
                  <span className="text-sm text-gray-500">
                    Plays: {course.plays ? course.plays : 0}
                  </span>
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg"
                    onClick={() =>
                      navigate("/course-details", {
                        state: {
                          title: course.title,
                        },
                      })
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>

      <FooterComponent path="/homescreen" />
    </div>
  );
};

export default TrainingCoursesPage;

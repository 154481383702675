import React from "react";
import Header from "./Header";
import module1 from "../../images/coursedetails/1.png";
import module2 from "../../images/coursedetails/2.png";
import module3 from "../../images/coursedetails/3.png";
import module4 from "../../images/coursedetails/4.png";
import { useNavigate } from "react-router-dom";

const TrainingModules = () => {
  const navigate = useNavigate();
  const modules = [
    {
      title:
        "Module 2 - Looking at all the dimensions to understand the person",
      completed: "No",
      image: module2,
    },
    {
      title: "Module 3 - Seeing the perspective of the person with dementia",
      completed: "No",
      image: module3,
    },
    {
      title:
        "Module 4 - How best to record behaviour to facilitate understanding",
      completed: "No",
      image: module4,
    },
  ];

  const moduleDetails = {
    title: "Module 1: What is dementia?",
    completed: "Not Completed",
    duration: "20m",
    rating: "4.8/5.0",
    image: module1,
    description: [
      "Increase awareness about different types of dementia",
      "The Brain",
      "The bookcase model of memory",
      "Symptoms of dementia",
      "Factors that impact the person's ability during the day",
    ],
  };

  return (
    <div className="min-h-screen bg-white py-8 flex flex-col items-center">
      <div className="container mx-auto px-4">
        <Header />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <h2 className="text-2xl font-bold mb-6">6D Training for Teams</h2>
            {modules.map((module, index) => (
              <div
                key={index}
                className="bg-white p-4 rounded-lg shadow-lg mb-4"
              >
                <div className="flex items-center">
                  <img
                    src={module.image}
                    alt={module.title}
                    className="w-32 object-cover rounded-lg mr-4"
                  />
                  <div>
                    <h3 className="text-xl font-bold mb-1">{module.title}</h3>
                    <p className="text-gray-700 mb-2">
                      Completed: {module.completed}
                    </p>

                    <button className="bg-blue-900 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-blue-700"
                     onClick={() => {
                      navigate("/mediaplayer");
                    }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <img
                src={moduleDetails.image}
                alt={moduleDetails.title}
                className="w-full  object-cover rounded-lg mb-4"
              />
              <h3 className="text-xl font-bold mb-2">{moduleDetails.title}</h3>
              <div className=" mb-4">
                <p className="bg-white px-8 py-4 w-[40%] rounded-3xl shadow-lg text-gray-700">
                  {moduleDetails.completed}
                </p>
                <p className=" text-gray-700 mt-2 flex items-center">
                  <svg
                    className="w-4 h-4 text-blue-900"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                  </svg>
                  <span className="ml-2">{moduleDetails.duration}</span>
                </p>
                <p className=" text-gray-700 mt-2 flex items-center">
                  <svg
                    className="w-4 h-4 text-blue-900"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 15l-5.878 3.09 1.123-6.545L.488 6.909l6.561-.953L10 0l2.951 5.956 6.561.953-4.757 4.636 1.123 6.545z" />
                  </svg>
                  <span className="ml-2"> {moduleDetails.rating}</span>
                </p>
              </div>
              <ul className="list-disc list-inside text-gray-700 mb-4">
                {moduleDetails.description.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <div className="flex justify-center">
                <button className="bg-blue-900 text-white px-20 py-2 rounded-lg shadow-lg hover:bg-blue-700"
                 onClick={() => {
                  navigate("/mediaplayer");
                }}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrainingModules;

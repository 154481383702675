// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { customerList } from "../../apiFunction/ApiFunctions";
import FooterComponent from "./shared/FooterComponent";

const ExpiredSubscriptionCustomerPage = () => {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    setCustomers(customerList('inactive'));
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <div className="bg-customBlue w-full h-[70px]">
        <h2 className="text-3xl text-center text-white mb-8 mt-4">
          Customers with Expired Subscriptions
        </h2>
      </div>
      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
            {customers.map((customer, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center"
              >
                <img
                  src={customer.logo}
                  alt={customer.name}
                  className="w-32 h-32 rounded-full mb-4"
                />
                <h3 className="text-xl font-semibold mb-2">{customer.name}</h3>
                <p className="text-gray-600 mb-4">{customer.address}</p>
                <button className="w-full bg-blue-950 text-white py-2 px-6 rounded-3xl">
                  View Details
                </button>
              </div>
            ))}
          </div>
        </div>
      </main>

      <FooterComponent path='/homescreen' />
    </div>
  );
};

export default ExpiredSubscriptionCustomerPage;

import { useNavigate } from "react-router-dom";

const FooterComponent = ({ path, state={} }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center mb-8">
      <button
        className="bg-blue-950 text-white text-xl py-3 px-10 rounded-lg"
        onClick={() => navigate(path, {state})}
      >
        Back
      </button>
    </div>
  );
};

export default FooterComponent;

import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

const Dashboard = () => {
  const navigate = useNavigate();
  const activeTrainingCourses = [
    {
      name: "6D Training for Teams",
      progress: 80,
      completion_text: "80% Complete",
    },
    {
      name: "6D Specialist Team Training",
      progress: 50,
      completion_text: "50% Complete",
    },
    {
      name: "6D Advanced Team Training",
      progress: 30,
      completion_text: "30% Complete",
    },
  ];

  const upcomingLiveWebinars = [
    {
      name: "Session 1: Understanding Symptoms",
      date: "20th Nov 2024",
    },
    {
      name: "Session 2: Care Techniques",
      date: "11th Dec 2024",
    },
    {
      name: "Session 3: Managing Stress",
      date: "19th Dec 2024",
    },
  ];

  return (
    <div className="min-h-screen bg-white p-4 md:p-8">
      <Header />

      <div className=" grid grid-cols-1 lg:grid-cols-6 gap-12">
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-semibold text-[#0089ad]">
            Welcome, Mary
          </h3>
          <img
            src={require("../../images/profile-image.png")}
            alt="Profile"
            className="mt-6 h-25 w-25 rounded-lg object-cover"
          />
        </div>

        <div className="lg:col-span-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <h2 className="text-2xl font-semibold mb-4">
              Your Active Training Courses
            </h2>
            <div className="space-y-4">
              {activeTrainingCourses.map((course, index) => (
                <div key={index}>
                  <div className="flex justify-between">
                    <h3 className="text-2xl">{course.name}</h3>
                    <span className="text-lg text-gray-600">
                      {course.completion_text}
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-8">
                    <div
                      className="bg-green-500 h-2.5 rounded-full"
                      style={{ width: `${course.progress}%` }}
                    ></div>
                  </div>
                </div>
              ))}
              {/* <div>
                <h3 className="font-semibold">6D Training for Teams</h3>
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-1">
                  <div
                    className="bg-green-500 h-2.5 rounded-full"
                    style={{ width: "80%" }}
                  ></div>
                </div>
                <span className="text-sm text-gray-600">80% Complete</span>
              </div>
              <div>
                <h3 className="font-semibold">6D Specialist Team Training</h3>
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-1">
                  <div
                    className="bg-green-500 h-2.5 rounded-full"
                    style={{ width: "50%" }}
                  ></div>
                </div>
                <span className="text-sm text-gray-600">50% Complete</span>
              </div>
              <div>
                <h3 className="font-semibold">6D Advanced Team Training</h3>
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-1">
                  <div
                    className="bg-green-500 h-2.5 rounded-full"
                    style={{ width: "30%" }}
                  ></div>
                </div>
                <span className="text-sm text-gray-600">30% Complete</span>
              </div> */}
            </div>
            <div className="text-center">
              <button
                className="mt-6 mb-2 px-10 py-2 bg-blue-900 text-white rounded-3xl shadow hover:bg-blue-700"
                onClick={() => {
                  navigate("/activetrainings");
                }}
              >
                View
              </button>
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg shadow mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              Your Upcoming Live Webinars
            </h2>
            <ul className="space-y-4">
              {upcomingLiveWebinars.map((session, index) => (
                <li key={index} className="flex justify-between">
                  <span className="text-2xl">{session.name}</span>
                  <span className="text-2xl">{session.date}</span>
                </li>
              ))}
              {/* <li className="flex justify-between">
                <span>Session 1: Understanding Symptoms</span>
                <span>20th Nov 2024</span>
              </li>
              <li className="flex justify-between">
                <span>Session 2: Care Techniques</span>
                <span>11th Dec 2024</span>
              </li>
              <li className="flex justify-between">
                <span>Session 3: Managing Stress</span>
                <span>19th Dec 2024</span>
              </li> */}
            </ul>
            <div className="text-center">
              <button
                className="mt-6 mb-2 px-10 py-2 bg-blue-900 text-white rounded-3xl shadow hover:bg-blue-700"
                onClick={() => {
                  navigate("/upcomingtrainings");
                }}
              >
                View
              </button>
            </div>
          </div>
        </div>

        <div className="space-y-28">
          <div className="bg-white p-4 rounded-lg shadow flex flex-col items-center">
            <div className="flex flex-row">
              <img
                src={require("../../images/dashboard/completedtraining.png")}
                alt="Completed Training"
                className="h-25 mb-4"
              />
              <h2 className="text-xl text-[#202759] text-center">
                Your Completed Training
              </h2>
            </div>

            <button
              className="px-10 py-2 mt-4 bg-blue-900 text-white rounded-3xl shadow hover:bg-blue-700"
              onClick={() => {
                navigate("/completedtrainings");
              }}
            >
              View
            </button>
          </div>

          <div className="bg-white p-4 rounded-lg shadow flex flex-col items-center">
            <div className="flex flex-row justify-between">
              <img
                src={require("../../images/dashboard/supportingmaterial.png")}
                alt="Supporting Material"
                className="h-25 mb-4"
              />
              <h2 className="text-xl text-[#202759] text-center">
                6D Supporting Material
              </h2>
            </div>
            <button
              className="px-10 py-2 mt-4 bg-blue-900 text-white rounded-3xl shadow hover:bg-blue-700"
              onClick={() => {
                navigate("/supportingmaterials");
              }}
            >
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

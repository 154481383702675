import React from 'react';
import Modal from 'react-modal';

const VideoModal = ({ isOpen, onRequestClose, videoUrl }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxHeight: '80%',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Video Modal"
      ariaHideApp={false}
    >
      <div className="flex justify-end">
        <button onClick={onRequestClose} className="text-black text-lg">
          &times;
        </button>
      </div>
      <div className="flex justify-center">
        <video
          width="100%"
          height="400"
          controls
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Modal>
  );
};

export default VideoModal;

import React from "react";
import Header from "./Header";
import module1 from "../../images/coursedetails/1.png";
import { useNavigate } from "react-router-dom";

const MediaPlayer = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-white py-8 flex flex-col items-center">
      <div className="container mx-auto px-4">
        <Header />
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="lg:w-1/4">
            <h2 className="text-3xl font-bold mb-5">6D Team Training</h2>
            <p className="text-2xl mb-4">
              Module 1: <br /> <br />
              What is dementia ?
            </p>
          </div>
          <div className="lg:w-3/4">
            <video
              className="w-full"
              // src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
              // src="https://dih-test.b-cdn.net/6D%20Dementia%20Training/Intertrade%20Ireland%20Seedcorn%20Video%20(1).mp4"
              src="https://dih-test.b-cdn.net/6D%20Dementia%20Training/Frontal%20Lobe%20V2.mp4"
              poster={module1}
              //  width={500}
              //  height={500}
              controls
            />
            <div className="mt-12 flex justify-center">
              <button
                className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
            </div>
            {/* <img
              src={module1}
              alt="What is dementia?"
              className="w-full h-64 object-cover rounded-lg mb-4"
            />
            <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center">
              <div className="w-full flex justify-between items-center mb-4">
                <button className="text-gray-700 hover:text-blue-600">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    ></path>
                  </svg>
                </button>
                <button className="text-gray-700 hover:text-blue-600">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 12H5"
                    ></path>
                  </svg>
                </button>
                <button className="text-gray-700 hover:text-blue-600">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14"
                    ></path>
                  </svg>
                </button>
                <button className="text-gray-700 hover:text-blue-600">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                </button>
                <button className="text-gray-700 hover:text-blue-600">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 4v16c0 1.333 2 2 2 2s2-.667 2-2V4H4z"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="w-full">
                <div className="relative w-full">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <button className="bg-blue-600 text-white p-2 rounded-full shadow-lg hover:bg-blue-700">
                      <svg
                        className="w-8 h-8"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 2L22 12 10 22V2z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="w-full bg-gray-200 rounded-lg h-2 mt-4">
                  <div className="bg-blue-600 h-2 rounded-lg" style={{ width: '30%' }}></div>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p className="text-gray-600">Total length: 2 minutes 47 seconds</p>
                  <div className="flex space-x-2">
                    <button className="text-gray-700 hover:text-blue-600">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 4v16c0 1.333 2 2 2 2s2-.667 2-2V4H4z"
                        ></path>
                      </svg>
                    </button>
                    <button className="text-gray-700 hover:text-blue-600">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        ></path>
                      </svg>
                    </button>
                    <button className="text-gray-700 hover:text-blue-600">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14"
                        ></path>
                      </svg>
                    </button>
                    <button className="text-gray-700 hover:text-blue-600">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 19l-7-7 7-7"
                        ></path>
                      </svg>
                    </button>
                    <button className="text-gray-700 hover:text-blue-600">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaPlayer;

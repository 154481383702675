import React from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { useLocation } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";
import { activeCourse } from "../../apiFunction/ApiFunctions";

const SubscriptionPage = () => {
  // const subscriptions = [
  //   {
  //     name: "6D Training for Teams",
  //     staffMembers: 10,
  //     renewalDate: "1st March 2025",
  //     status: "Subscribed",
  //   },
  //   {
  //     name: "6D Specialist Team Training",
  //     staffMembers: 24,
  //     renewalDate: "1st July 2024",
  //     status: "Expired",
  //   },
  //   {
  //     name: "6D Advanced Team Training",
  //     staffMembers: 12,
  //     renewalDate: "1st December 2024",
  //     status: "Subscribed",
  //   },
  // ];
  const subscriptions = activeCourse();
  const location = useLocation();
  const { state } = location;

  return (
    <div className="min-h-screen bg-gray-100">
      <HeaderComponent />
      <div className="container mx-auto">
        <div className="mb-8 ml-[-2%]">
          <h2 className="text-2xl font-medium text-customBlue">
            Subscriptions for: {state.name}
          </h2>
        </div>
        <div className="space-y-12 mb-8">
          {subscriptions.map((subscription, index) => (
            <div
              key={index}
              className={`flex justify-between items-center p-4 h-24 rounded-lg shadow-md ${subscription.status === 'Expired' ? 'bg-yellow-400' : 'bg-white'}`}
            >
              <span className="text-xl text-customBlue">{subscription.title}</span>
              <span className="text-gray-500">Staff Members: {subscription.staffMembers}</span>
              <span className="text-gray-500">Renewal Date: {subscription.renewalDate}</span>
              <button
                className={`text-white w-32 py-2 rounded-lg ${subscription.status === 'Expired' ? 'bg-red-500' : 'bg-green-500'}`}
              >
                {subscription.status}
              </button>
            </div>
          ))}
        </div>
        <FooterComponent path='/organization-dashboard' state={state}/>
      </div>
    </div>
  );
};

export default SubscriptionPage;

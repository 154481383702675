import React from "react";
import { useNavigate } from "react-router-dom";
import module1 from "../../images/coursedetails/1.png";
import module2 from "../../images/coursedetails/2.png";
import module3 from "../../images/coursedetails/3.png";
import Header from "./Header";

const SupportingMaterials = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-white py-8 px-4 md:px-8 lg:px-16">
      <div className="container mx-auto">
        <Header />
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          {/* Favourite Modules */}
          <div className="col-span-1">
            <h2 className="text-lg font-semibold mb-4 text-center">
              Favourite Modules
            </h2>
            <div className="space-y-8">
              {[
                { title: "What is Dementia?", image: module1 },
                { title: "Changes in Behaviour", image: module2 },
                { title: "Stress and Behaviour", image: module3 },
              ].map((module, index) => (
                <div key={index} className="flex items-center space-x-8">
                  <img
                    src={module.image}
                    alt={module}
                    className="w-24 h-20 object-cover rounded-md"
                  />
                  <div className="w-24 text-center">
                    <h3>{module.title}</h3>
                    <button
                      className="ml-auto text-3xl text-blue-900 hover:text-blue-700"
                      onClick={() => {
                        navigate("/mediaplayer");
                      }}
                    >
                      ▶
                    </button>
                  </div>
                </div>
              ))}

              <button
                className="text-[#6d6d6d]  bg-white p-4 rounded-3xl shadow-lg hover:bg-gray-300"
                onClick={() => {
                  navigate("/trainingmodules");
                }}
              >
                View All Modules
              </button>
            </div>
          </div>

          {/* Research Papers */}
          <div className="col-span-1 md:col-span-1">
            <h2 className="text-lg font-semibold mb-4 text-center">
              Research Papers
            </h2>
            <div className="space-y-4">
              {[
                {
                  title: "Dementia Stress and Behaviour",
                  author: "Fred Bloggs",
                  date: "15/02/2023",
                },
                {
                  title: "Psychological well-being in Dementia",
                  author: "John Jones",
                  date: "03/04/1999",
                },
              ].map((paper, index) => (
                <div
                  key={index}
                  className="bg-white p-4 rounded-3xl shadow-lg text-center "
                >
                  <h3 className="text-md font-semibold">{paper.title}</h3>
                  <p className="text-sm text-gray-600 mt-4">
                    Author: {paper.author}
                  </p>
                  <a
                    // href="https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me%20Ideas.pdf"
                    href="https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me.pdf"
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    <button className="mt-4 bg-blue-900 text-white px-4 py-2 rounded-lg">
                      Download
                    </button>
                  </a>
                  <p className="mt-4 mb-4 text-sm text-gray-600">
                    Publication Date: {paper.date}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* 6D Handbooks */}
          <div className="col-span-1">
            <h2 className="text-lg font-semibold mb-4 text-center">
              6D Handbooks
            </h2>
            <div className="space-y-4  bg-white p-4 rounded-3xl shadow-lg ">
              <div className="">
                <p className="text-md font-semibold">Company Guidelines</p>
                <p className="text-sm text-gray-600 mt-4">Policy 1 Details</p>
              </div>
              <div className="">
                <p className="text-md font-semibold">Risk Manual</p>
                <p className="text-sm text-gray-600 mt-4">
                  Risk Manual Details
                </p>
              </div>
            </div>
          </div>

          {/* Assessments */}
          <div className="col-span-1">
            <h2 className="text-lg font-semibold mb-4 text-center">
              Assessments
            </h2>
            <div className="space-y-4 bg-white p-4 rounded-3xl shadow-lg">
              {["Care Staff Training", "Dementia Team Training"].map(
                (assessment, index) => (
                  <div key={index} className="">
                    <p className="text-md font-semibold">{assessment}</p>
                    <p className="text-sm text-gray-600">
                      Assessment {index + 1}
                    </p>
                    <button className="mt-4 bg-blue-900 text-white px-4 py-2 rounded-lg ">
                      Start Assessment
                    </button>
                  </div>
                )
              )}
            </div>
          </div>

          {/* PDF Documents */}
          <div className="col-span-1">
            <h2 className="text-lg font-semibold mb-4 text-center">
              PDF Documents
            </h2>
            <div className="flex flex-row">
              {["Training Introduction", "Training Objectives"].map(
                (doc, index) => (
                  <div
                    key={index}
                    className=" bg-white p-4 rounded-3xl shadow-lg "
                  >
                    <p className="text-md font-semibold text-center">{doc}</p>
                    <a
                      // href="https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me%20Ideas.pdf"
                      href="https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me.pdf"
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      <button className="mt-2 bg-blue-900 text-white px-4 py-2 rounded-lg">
                        Download
                      </button>
                    </a>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-12">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default SupportingMaterials;

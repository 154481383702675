import React, { useEffect } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import FooterComponent from "./shared/FooterComponent";
import { userInfo } from "../../apiFunction/ApiFunctions";
import { useLocation } from "react-router-dom";

const WebinarRegistrationListPage = () => {
  const users = userInfo('user', 1000);
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    // setCustomers(customerList());
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <div className="bg-customBlue w-full text-center text-white">
        <h2 className="text-3xl mb-2 mt-4">
          Webinar Registration
        </h2>
        <p className="font-medium font-semibold">{state.title}</p>
        <p>Presented by {state.presentedBy}</p>
        <p className="mb-4">{state.date} at {state.time}</p>
      </div>
      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 w-full">
            {users.map((user, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center"
              >
                <img
                  src={user.image}
                  alt={user.firstname}
                  className="w-32 h-32 rounded-full mb-4"
                />
                <h3 className="text-xl font-semibold mb-2">{user.firstname} {user.lastname}</h3>
                <p className="text-gray-600 mb-4">{user.designation}</p>
                {/* <button className="w-full bg-blue-950 text-white py-2 px-6 rounded-3xl">
                  View Details
                </button> */}
              </div>
            ))}
          </div>
        </div>
      </main>

      <FooterComponent path='/upcoming-training-webinars' />
    </div>
  );
};

export default WebinarRegistrationListPage;

// src/components/CreateWebinarPage.js

import React, { useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { format, parse } from "date-fns";

const CreateWebinarPage = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [presenter, setPresenter] = useState("");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("10:00");
  const [thumbnail, setThumbnail] = useState(null);
  const navigate = useNavigate();

  const handleThumbnailChange = (e) => {
    setThumbnail(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedDate = format(date, "MMMM d, yyyy");

    const parsedTime = parse(time, "HH:mm", new Date());
    const formattedTime = format(parsedTime, "hh:mm a");

    const formData = {
      title: name,
      subtitle: description,
      presentedBy: presenter,
      date: formattedDate,
      time: formattedTime,
      image: thumbnail,
    };

    const sessionWebinarData = JSON.parse(
      sessionStorage.getItem("webinarData")
    );

    let updatedFormData;
    if (
      sessionWebinarData !== null &&
      !Array.isArray(sessionWebinarData) &&
      typeof sessionWebinarData === "object"
    ) {
      updatedFormData = [formData, sessionWebinarData];
    } else if (Array.isArray(sessionWebinarData)) {
      updatedFormData = [formData, ...sessionWebinarData];
      console.log(updatedFormData);
    } else {
      updatedFormData = [formData];
    }

    sessionStorage.setItem("webinarData", JSON.stringify(updatedFormData));

    // Log the form data
    console.log("Form Data as JSON:", JSON.stringify(updatedFormData, null, 2));

    // Navigate to homescreen
    navigate("/homescreen");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <form
          className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4 mt-[-4%]"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-customBlue ml-[-1%]">
            Create Live Webinar
          </h2>
          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Name:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Name of Live Webinar"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Description:
            </label>
            <textarea
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Description of the Live Webinar"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Presenter:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Enter the Name of the Main Presenter on the Webinar"
              value={presenter}
              onChange={(e) => setPresenter(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Select Date:
            </label>
            <div className="flex space-x-2">
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="MMMM d, yyyy"
                className="border border-gray-300 rounded-lg p-3 w-full"
                required
              />
              <TimePicker
                onChange={setTime}
                value={time}
                className="border border-gray-300 rounded-lg p-3 w-1/3"
                required
              />
            </div>
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Upload Webinar Thumbnail:
            </label>
            <input
              type="file"
              className="border border-gray-300 rounded-lg p-3"
              onChange={handleThumbnailChange}
              required
            />
            {thumbnail && (
              <img
                src={thumbnail}
                alt="Webinar Thumbnail"
                className="mt-4 w-32 h-32 rounded-lg object-cover"
              />
            )}
          </div>

          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-44"
            >
              Save
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CreateWebinarPage;

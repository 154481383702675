import React from "react";
import { useNavigate } from "react-router-dom";
import pain from "../../images/upcomingtraining/1.png";
import communication from "../../images/upcomingtraining/2.png";
import behaviour from "../../images/upcomingtraining/3.png";
import Header from "./Header";

const webinars = [
  {
    date: "20th July 2023 - 10:00 AM",
    title: "Pain",
    presenter: "Dr. Emily Johnson",
    description: "Pain in dementia patients",
    image: pain,
  },
  {
    date: "27th July 2023 - 2:00 PM",
    title: "Effective Communication in Dementia",
    presenter: "Dr. Sarah Thompson",
    description: "Improving communication skills in dementia care.",
    image: communication,
  },
  {
    date: "20th August 2023 - 3:00 PM",
    title: "Understanding Changes in Behaviour",
    presenter: "Dr. Mark Roberts",
    description: "Supporting the person who is distressed",
    image: behaviour,
  },
];

const WebinarCard = ({
  date,
  title,
  presenter,
  description,
  image,
  navigate,
}) => (
  <div className="bg-white p-4 rounded-lg shadow-lg">
    <img
      src={image}
      alt={title}
      className="w-full  object-cover rounded-lg mb-4"
    />
    <h2 className="text-lg  mb-2">{date}</h2>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600 mb-2">Presented by: {presenter}</p>
    <p className="text-gray-700 mb-4">{description}</p>
    <button
      className="bg-blue-900 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-blue-700 mt-8"
      onClick={() => {
        navigate("/success");
      }}
    >
      Register
    </button>
  </div>
);

const LiveTrainingWebinars = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-white py-8">
      <div className="container mx-auto px-4">
        <Header />
        <div className="w-1/2 flex justify-between items-center">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">
            Live Training Webinars
          </h2>
          <button
            className="bg-[#0089ad] text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate("/recordedwebinars");
            }}
          >
            Recorded Sessions
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {webinars.map((webinar, index) => (
            <WebinarCard key={index} {...webinar} navigate={navigate} />
          ))}
        </div>
        <div className="mt-16 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default LiveTrainingWebinars;

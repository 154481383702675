import React from "react";
// import { useNavigate } from "react-router-dom";
import HeaderComponent from "./shared/HeaderComponent";
import FooterComponent from "./shared/FooterComponent";
import dementia from "../../images/dementia.png";
import behaviourChange from "../../images/user2.png";
import stressBehaviour from "../../images/user1.png";
import { useLocation } from "react-router-dom";
// import homeIcon from "../../images/home_icon.png";

const CourseDetailsPage = () => {
//   const navigate = useNavigate();
  const favouriteModules = [
    {
      title: "Module 2 - Responding to changes in behaviour to reduce distress",
      image: behaviourChange,
      buttonLabel: "Edit",
    },
    {
      title: "Module 3 - Tools to help understand changes in behaviour",
      image: stressBehaviour,
      buttonLabel: "Edit",
    },
    {
      title: "Module 4 - The experience of the person with dementia living in a care home",
      image: dementia,
      buttonLabel: "Edit",
    },
  ];

  const location = useLocation();
  const { state } = location;

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <main className="flex flex-col items-center p-6 mt-[-3%]">
        <h1 className="text-3xl font-semibold text-customBlue mt-4">
          {state.title}
        </h1>
        <div className="w-full flex justify-between items-start mt-6 space-x-6">
          <div className="flex flex-col space-y-6 ">
            {favouriteModules.map((module, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg flex p-4 items-center"
              >
                <img
                  src={module.image}
                  alt={module.title}
                  className="w-32 h-32 object-cover rounded-lg"
                />
                <div className="ml-4">
                  <h3 className="font-semibold text-lg">{module.title}</h3>
                  <button className="bg-blue-600 text-white py-2 px-4 rounded-lg mt-4">
                    {module.buttonLabel}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="w-2/3 bg-white rounded-lg shadow-lg p-6">
            <img
              src={dementia}
              alt="Dementia"
              className="w-full h-64 object-cover rounded-lg"
            />
            <div className="mt-4">
              <h2 className="text-2xl font-semibold">
                Module 1: Understanding dementia and changes in behaviour
              </h2>
              <div className="flex items-center mt-2">
                <span className="text-gray-600">1h 30m</span>
                <span className="ml-4 text-gray-600">4.8/5.0</span>
              </div>
              <ul className="mt-4 list-disc list-inside text-gray-700">
                <li>Learn to understand what is dementia</li>
                <li>Understand the different types of dementia</li>
                <li>Identify common signs and symptoms of dementia to provide better care.</li>
              </ul>
              <button className="bg-blue-600 text-white py-2 px-4 rounded-lg mt-4">
                Edit
              </button>
            </div>
          </div>
        </div>
            
      </main>
      <FooterComponent path={'/training-courses'}/>
    </div>
  );
};

export default CourseDetailsPage;

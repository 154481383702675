import React from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  activeCourse,
  award,
  completedCourse,
} from "../../apiFunction/ApiFunctions";
import { useLocation } from "react-router-dom";
import awardsIcon from "../../images/awardsIcon.png";
import FooterComponent from "./shared/FooterComponent";

const UserProfilePage = () => {
  const location = useLocation();
  const { state } = location;

  const completedCourses = completedCourse();
  const activeCourses = activeCourse();
  const awards = award();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <div className="container mx-auto">
        <h2 className="text-3xl font-medium text-customBlue mb-8 ml-[-2%]">
          Overview for: {state.fullname}
        </h2>

        <div className="mb-8">
          <h3 className="text-xl font-medium mb-4 ml-[-1%]">
            Completed Courses
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-16">
            {completedCourses.map((course, index) => (
              <div
                key={index}
                className="bg-gray-200 shadow-md text-center rounded-xl overflow-hidden"
              >
                <img
                  src={course.image}
                  alt={course.title}
                  className="w-full h-48 object-cover p-6"
                />
                <div className="mb-4">
                  <h4 className="text-lg font-semibold">{course.title}</h4>
                  <p className="text-sm">{course.date}</p>
                  <p className="text-sm">Score: {course.score}</p>
                  <p className="text-sm">Modules: {course.modules}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-lg font-medium mb-4">
              Active Training Courses
            </h3>
            {activeCourses.map((course, index) => (
              <div key={index} className="mb-4">
                <div className="flex justify-between items-center">
                  <span>{course.title}</span>
                  <span>{course.progress}% Complete</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-green-500 h-2.5 rounded-full"
                    style={{ width: `${course.progress}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>

          <div>
            <h3 className="text-lg font-medium mb-4">
              Awards & Certifications
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {awards.map((award, index) => (
                <div
                  key={index}
                  className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center"
                >
                  <div className="mb-2">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-12 w-12 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 12a4 4 0 118 0 4 4 0 01-8 0zm7-9a2 2 0 100 4 2 2 0 000-4zm-7-1a2 2 0 100 4 2 2 0 000-4zM3 10a3 3 0 100 6 3 3 0 000-6zm3 8a3 3 0 00-3-3H2a4 4 0 110 8h1a3 3 0 003-3zm10 0a3 3 0 00-3-3h-1a4 4 0 110 8h1a3 3 0 003-3z"
                        clipRule="evenodd"
                      />
                    </svg> */}
                    <img
                      src={awardsIcon}
                      alt="Webinar Thumbnail"
                      className="w-24 h-full object-cover"
                    />
                  </div>
                  <p className="text-center text-sm">{award.date}</p>
                  <p className="text-center text-sm font-semibold">
                    {award.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <FooterComponent path='/user-list' state={state}/>
      </div>
    </div>
  );
};

export default UserProfilePage;

// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { customerList } from "../../apiFunction/ApiFunctions";
import { useNavigate } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";

const CustomerListPage = () => {
  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const sessionOrganizationData = JSON.parse(
      sessionStorage.getItem("organizationData")
    );
    console.log(sessionOrganizationData);
    const customers = customerList();

    // Only add sessionTrainingVideo if it is valid
    if (sessionOrganizationData) {
      console.log([...customers, ...sessionOrganizationData]);
      setCustomers([...customers, ...sessionOrganizationData]);
    } else {
      setCustomers(customers);
    }
    // setCustomers(customerList());
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4 mt-[-4%]">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">Customer List</h2>
            <button
              className="bg-yellow-500 text-white py-2 px-6 rounded-lg"
              onClick={() => navigate("/create-organization")}
            >
              Create
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
            {customers.map((customer, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center"
              >
                <img
                  src={customer.logo}
                  alt={customer.name}
                  className="w-32 h-32 rounded-full mb-4"
                />
                <h3 className="text-xl font-semibold mb-2">{customer.name}</h3>
                <p className="text-gray-600 mb-4">{customer.address}</p>
                <button
                  className="w-full bg-blue-950 text-white py-2 px-6 rounded-3xl"
                  onClick={() =>
                    navigate("/organization-dashboard", {
                      state: {
                        name: customer.name,
                        image: customer.logo,
                        address: customer.address,
                      },
                    })
                  }
                >
                  View Details
                </button>
              </div>
            ))}
          </div>
        </div>
      </main>

      <FooterComponent path='/homescreen' />
    </div>
  );
};

export default CustomerListPage;

import React, { useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { useNavigate, useLocation } from "react-router-dom";

const CreateManager = () => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [department, setDepartment] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [isDisabled, setIsDisabled] = useState(true);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the form data
    const formData = {
      name,
      role,
      department,
      phone,
      email: username,
      password,
      image,
    };

    // Check if any previous data is already there or not
    const sessionNewUser = JSON.parse(sessionStorage.getItem("newUser"));

    let updatedFormData;
    if (
      sessionNewUser !== null &&
      !Array.isArray(sessionNewUser) &&
      typeof sessionNewUser === "object"
    ) {
      updatedFormData = [sessionNewUser, formData];
    } else if (Array.isArray(sessionNewUser)) {
      updatedFormData = [...sessionNewUser, formData];
      console.log(updatedFormData);
    } else {
      updatedFormData = [formData];
    }

    // Save the form data to session storage
    sessionStorage.setItem("newUser", JSON.stringify(updatedFormData));

    // Log the form data
    console.log("Form Data as JSON:", JSON.stringify(updatedFormData, null, 2));

    // Navigate to homescreen
    navigate("/user-list", { state });
    // Handle form submission
    // console.log({ name, role, department, phone, username, password, image });
  };

  const handleClick = () => {
    console.log("This action is not allowed at this time.");
    if (isDisabled) {
      alert("This action is not allowed at this time.");
      navigate("/user-list", { state });
    }
  };

  return (
    // <div className="min-h-screen bg-gray-100 flex flex-col">
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <div className="container mx-auto">
        <h1 className="text-3xl font-medium text-customBlue mb-8 ml-[-2%]">
          Create Manager
        </h1>
        <form
          className="bg-white shadow-md rounded-lg p-8 mt-[-1%] flex mb-12"
          // onSubmit={handleSubmit}
        >
          <div className="w-5/6 pr-8">
            <div className="mb-4">
              <label className="block text-gray-700">Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full border p-2 rounded mt-1"
                required
              />
            </div>
            <div className="mb-4 w-1/3">
              <label className="block text-gray-700">Role:</label>
              <select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              >
                <option value="">Choose the Manager's Role</option>
                <option value="Administrator">Administrator</option>
                <option value="Manager">Manager</option>
              </select>
            </div>
            <div className="mb-4 w-1/3">
              <label className="block text-gray-700">Department:</label>
              <select
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              >
                <option value="">Department</option>
                <option value="HR">Care Home 1</option>
                <option value="Finance">Care Home 2</option>
                <option value="Operations">Care Home 3</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Phone Number:</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Username:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className={`bg-blue-950 text-white text-xl py-2 px-8 rounded-lg mt-12 ${
                  isDisabled ? "cursor-not-allowed opacity-50" : ""
                }`}
                onClick={
                  !isDisabled
                    ? () => {
                        /* Actual submit logic here */
                      }
                    : handleClick
                }
              >
                Create
              </button>
              {isDisabled && (
                <div
                  className="absolute inset-0"
                  onClick={handleClick}
                  style={{
                    backgroundColor: "transparent",
                    cursor: "not-allowed",
                  }}
                ></div>
              )}
            </div>
          </div>
          <div className="w-1/6 flex flex-col items-center">
            <div className="mb-4">
              {image ? (
                <img
                  src={image}
                  alt="Uploaded"
                  className="w-32 h-32 rounded-full object-cover"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-500">Upload Picture</span>
                </div>
              )}
            </div>
            <label
              htmlFor="file-upload"
              className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
            >
              Upload Picture
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateManager;

import { React, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "./shared/HeaderComponent";
import { userInfo } from "../../apiFunction/ApiFunctions";

const UserListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState([])
  const { state } = location;
  let count;

  useEffect(() => {
    const sessionNewUser = JSON.parse(sessionStorage.getItem("newUser"));
    console.log(sessionNewUser);
    const users = userInfo();
    count = users.length;

    // Only add sessionTrainingVideo if it is valid
    if (sessionNewUser) {
      console.log([...users, ...sessionNewUser]);
      setUsers([...users, ...sessionNewUser]);
    } else {
      setUsers(users);
    }
    // setCustomers(customerList());
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <div className="container mx-auto p-4">
        <hr className="border-t-2 border-gray-300 mb-4" />
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-semibold text-customBlue">
            User List for {state.name}
          </h1>
          <button
            className="bg-customBlue text-white px-4 py-2 rounded-lg"
            onClick={() => navigate("/create-user", { state })}
          >
            Create New User
          </button>
        </div>
        <p className="mt-[-1%] text-gray-500 mb-2">{count} Members</p>
        <hr className="border-t-2 border-gray-300 mb-4" />

        <div className="">
          {users.map((user, index) => (
            <div
              key={index}
              className="flex items-center p-4 border-b last:border-b-0"
            >
              <img
                src={user.image}
                alt={`${user.name}`}
                className="w-16 h-16 rounded-full mr-4"
              />
              <div className="flex-grow">
                <h2>
                  {user.name}
                </h2>
                <p className="text-xs text-gray-500">{user.role}</p>
              </div>
              <button
                className="bg-gray-200 px-4 py-2 rounded-lg"
                onClick={() =>
                  navigate("/user-profile", {
                    state: {
                      ...state,
                      fullname: user.name,
                    },
                  })
                }
              >
                View Profile
              </button>
            </div>
          ))}
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="w-2/4 flex justify-end">
            <button
              className="bg-blue-950 text-white text-xl py-3 px-10 rounded-lg"
              onClick={() =>
                navigate("/organization-dashboard", {
                  state,
                })
              }
            >
              Back
            </button>
          </div>
          <div className="w-1/3 flex justify-end">
            <button className="bg-orange-500 text-white px-10 py-3 rounded-3xl">
              Upload CSV
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserListPage;

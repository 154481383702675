import trainingImage from "../../images/training-image.png"; // Replace with actual path to image
import React, { useState } from "react";
import { userInfo } from "../../apiFunction/ApiFunctions";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [loginStatus, setLoginStatus] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = findUserByEmail(email);
    if (user) {
      if (user.password === password) {
        // Handle successful login
        setError("");
        // setLoginStatus(true);
        console.log("Login successful!");
        sessionStorage.setItem("user", JSON.stringify(user));
        if (user.role === "admin") {
          navigate("/homescreen");
        }
        if (user.role === "user") {
          navigate("/dashboard");
          console.log("user");
        }
      } else {
        setError("Incorrect password");
      }
    } else {
      setError("User not found");
    }
  };

  const findUserByEmail = (email) => {
    const users = userInfo();
    console.log(users);
    return users.find((user) => user.email === email);
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="flex flex-col sm:flex-row bg-white rounded-lg shadow-lg overflow-hidden w-full h-full">
        <div className="flex-1 flex items-center justify-center bg-gray-200 h-auto p-10 rounded-lg m-[40px]">
          <img
            src={trainingImage}
            alt="Training"
            className="w-full h-auto max-w-md"
          />
        </div>
        <div className="flex-1 flex flex-col items-center justify-center p-20 text-center h-full">
          <div className="max-w-md w-full space-y-8">
            <div>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Sign in to your account
              </h2>
            </div>

            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{error}</span>
                </div>
              )}

              <input type="hidden" name="remember" value="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <a href="./login" className="block mt-4">
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Sign in
                  </button>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

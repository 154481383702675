import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateUserComponent = () => {
  const auth = JSON.parse(sessionStorage.getItem("user"));
  console.log("auth", auth.role);
  return auth.role === "user" ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateUserComponent;

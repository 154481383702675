import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/dementia-logo.png";

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className="flex items-center justify-between mb-8">
      <div className="flex items-center">
        <img
          src={logo}
          alt="6D Dementia"
          className="w-16 h-16 mr-4"
          onClick={() => {
            navigate("/dashboard");
          }}
        />
      </div>
      <h1 className="text-3xl font-bold text-[#0089ad]">
        6D Dementia Training
      </h1>
      <div className="w-16 h-16">
        <svg
          style={{
            color: "#0089ad",
            fill: "#0089ad",
          }}
          viewBox="0 0 24 24"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <path d="M0 0h24v24H0V0z" fill="none"></path>
          <path d="M18 13c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1l2 1.06v-4.12L18 14v-1zM12 3 4 9v12h16v-2H6v-9l6-4.5 6 4.5v1h2V9l-8-6z"></path>
        </svg>
      </div>
    </header>
  );
};

export default Header;

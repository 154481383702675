import React from "react";
import Header from "./Header";
import image1 from "../../images/recordedwebinars/1.png";
import image2 from "../../images/recordedwebinars/2.png";
import image3 from "../../images/recordedwebinars/3.png";
import image4 from "../../images/recordedwebinars/4.png";
import { useNavigate } from "react-router-dom";

const RecordedWebinars = () => {
  const navigate = useNavigate();
  const webinars = [
    {
      title: "When someone wants to leave",
      presenter: "Dr. Emily Johnson",
      date: "June 15, 2022",
      duration: "1 hour 30 mins",
      image: image1,
    },
    {
      title: "How to effectively engage with carers",
      presenter: "Dr. James Smith",
      date: "July 5, 2022",
      duration: "2 hours",
      image: image2,
    },
    {
      title: "Unrecognised pain",
      presenter: "Dr. Sarah Adams",
      date: "July 20, 2022",
      duration: "1 hour 45 mins",
      image: image3,
    },
    {
      title: "Supporting with personal care",
      presenter: "Dr. Emily Johnson",
      date: "June 16, 2022",
      duration: "1 hour 20 mins",
      image: image4,
    },
  ];

  return (
    <div className="min-h-screen flex flex-col items-center bg-white py-8">
      <div className="container mx-auto px-4">
        <Header />
        <h2 className="text-3xl font-bold mb-2 ">Recorded Live Webinars</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {webinars.map((webinar, index) => (
            <div key={index} className="bg-white p-4 rounded-lg shadow-lg">
              <img
                src={webinar.image}
                alt={webinar.title}
                className="w-full object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold mb-2">{webinar.title}</h3>
                <p className="text-gray-700 mb-1">By: {webinar.presenter}</p>
                <p className="text-gray-700 mb-1">Date: {webinar.date}</p>
                <p className="text-gray-700 mb-4">
                  Duration: {webinar.duration}
                </p>
                <div className="flex justify-center">
                  <button className="bg-blue-900 text-white  px-8 py-2 rounded-lg shadow-lg hover:bg-blue-700"
                   onClick={() => {
                    navigate("/mediaplayer");
                  }}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-8 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecordedWebinars;

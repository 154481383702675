// src/components/CreateWebinarPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { trainingCourses } from "../../apiFunction/ApiFunctions";
import { useNavigate } from "react-router-dom";

const CreateTrainingVidePage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [url, setUrl] = useState("");
  // const [updatedFormData, setUpdatedFormData] = useState([]);
  // const [dateTime, setDateTime] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [image, setImage] = useState(null);

  const navigate = useNavigate();

  // const handleThumbnailChange = (e) => {
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };

  useEffect(() => {
    setCourseList(trainingCourses());
  }, []);

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Convert description to array
    const inputString = description.replace(/\n/g, ",\n");
    const descriptionArray = inputString.split(/\s*,\s*\n\s*/); // Handles extra spaces and newlines

    // Prepare the form data
    const formData = {
      title,
      description: descriptionArray,
      selectedCourse,
      url,
      image,
    };

    // Check if any previous data is already there or not
    const sessionTrainingVideo = JSON.parse(
      sessionStorage.getItem("trainingVideo")
    );

    let updatedFormData;
    if (
      sessionTrainingVideo !== null &&
      !Array.isArray(sessionTrainingVideo) &&
      typeof sessionTrainingVideo === "object"
    ) {
      updatedFormData = [formData, sessionTrainingVideo];
    } else if (Array.isArray(sessionTrainingVideo)) {
      updatedFormData = [formData, ...sessionTrainingVideo];
      console.log(updatedFormData);
    } else {
      updatedFormData = [formData];
    }

    // Save the form data to session storage
    sessionStorage.setItem("trainingVideo", JSON.stringify(updatedFormData));

    // Log the form data
    console.log("Form Data as JSON:", JSON.stringify(updatedFormData, null, 2));

    // Navigate to homescreen
    navigate("/homescreen");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <form
          className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4 mt-[-4%]"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-customBlue ml-[-1%]">
            Create Training Video
          </h2>
          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Name:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Name of Live Webinar"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Description:
            </label>
            <textarea
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Description of the Live Webinar"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Training Courses:
            </label>
            <select
              className="w-full p-3 border border-gray-300 rounded-lg bg-white"
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              <option value="" disabled selected>
                Choose your course
              </option>
              {courseList &&
                courseList.map((item) => (
                  <option value={item.title}>{item.title}</option>
                ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Video URL:
            </label>
            <input
              className="border border-gray-300 rounded-lg p-3"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Upload Webinar Thumbnail:
            </label>
            <input
              type="file"
              className="border border-gray-300 rounded-lg p-3 bg-white"
              onChange={handleThumbnailChange}
            />
            {image && (
              <img
                src={image}
                alt="Webinar Thumbnail"
                className="mt-4 w-32 h-32 rounded-lg object-cover"
              />
            )}
          </div>

          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-44"
            >
              Save
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CreateTrainingVidePage;

import React, { useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { useNavigate } from "react-router-dom";
import { userInfo } from "../../apiFunction/ApiFunctions";
import { useLocation } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";

const OrganizationDashboardPage = () => {
  const location = useLocation();
  const { state } = location;
  const [isEditable, setIsEditable] = useState(false);
  const [organisationName, setOrganisationName] = useState(state.name);
  const [organisationType, setOrganisationType] = useState("Care Home");
  const [address, setAddress] = useState(state.address);
  const [phone, setPhone] = useState("0128 465 7886");
  const navigate = useNavigate();
  //   const user = user;

  function enableEdit() {
    setIsEditable(true);
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <HeaderComponent />
      <div className="container mx-auto p-6">
        {state && (
          <div className="flex mb-8">
            <img
              src={state.image}
              alt="Loughview Care Home"
              className="w-32 h-32 rounded-full mr-6"
            />
            <div>
              <h2 className="text-3xl text-customBlue mt-12">{state.name}</h2>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 ml-40">
          <div>
            <label className="block mb-2">Organisation Name</label>
            <input
              type="text"
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
              className="w-full border p-2 rounded"
              readOnly={!isEditable}
            />
          </div>
          <div>
            <label className="block mb-2">Organisation Type</label>
            <input
              type="text"
              value={organisationType}
              onChange={(e) => setOrganisationType(e.target.value)}
              className="w-full border p-2 rounded"
              readOnly={!isEditable}
            />
          </div>
          <div>
            <label className="block mb-2">Address</label>
            <textarea
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full border p-2 rounded"
              rows="2"
              readOnly={!isEditable}
            />
          </div>
          <div>
            <label className="block mb-2">Website</label>
            <a
              href="http://www.loughviewch.com"
              target="_blank"
              rel="noopener noreferrer"
              className="block mb-2 text-blue-600"
            >
              www.loughviewch.com
            </a>
            <div className="flex justify-center gap-2">
              {isEditable ? (
                <>
                  <button
                    className="bg-red-600 p-1 text-white rounded-lg w-16"
                    onClick={() => setIsEditable(false)}
                  >
                    Cancel
                  </button>
                  <button className="bg-green-600 p-1 text-white rounded-lg w-16">
                    Save
                  </button>
                </>
              ) : (
                <button
                  className="bg-gray-600 p-1 text-white rounded-lg w-16"
                  onClick={enableEdit}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          <div>
            <label className="block mb-2">Phone</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full border p-2 rounded"
              readOnly={!isEditable}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 ml-24">
          <div>
            <h3 className="text-2xl font-semibold mb-4">Administrators</h3>
            <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
              {userInfo("admin") &&
                userInfo("admin").map((item) => (
                  <div className="flex items-center mb-4">
                    <img
                      src={item.image}
                      alt="Admin"
                      className="w-12 h-12 rounded-full mr-4"
                    />
                    <div>
                      <h4 className="font-semibold">
                        {item.firstname} {item.lastname}
                      </h4>
                      <span className="text-gray-600">{item.designation}</span>
                    </div>
                    <button
                      className="ml-auto bg-gray-100 text-gray-700 p-1 rounded-3xl border border-gray-400 w-32"
                      onClick={() =>
                        navigate("/user-profile", {
                          state: {
                            ...state,
                            fullname: item.firstname + " " + item.lastname,
                          },
                        })
                      }
                    >
                      Profile
                    </button>
                  </div>
                ))}
              <div className="flex justify-center">
                <button
                  className="bg-white text-gray-700 p-1 rounded-2xl shadow w-32 border border-gray-400"
                  onClick={() => navigate("/create-user")}
                >
                  Create
                </button>
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-2xl font-semibold mb-4">Users</h3>
            <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
              {userInfo("user") &&
                userInfo("user").map((item) => (
                  <div className="flex items-center mb-4">
                    <img
                      src={item.image}
                      alt="User"
                      className="w-12 h-12 rounded-full mr-4"
                    />
                    <div>
                      <h4 className="font-semibold">
                        {item.firstname} {item.lastname}
                      </h4>
                    </div>
                    <button
                      className="ml-auto bg-gray-100 text-gray-700 p-1 rounded-3xl w-32 border border-gray-400"
                      onClick={() =>
                        navigate("/user-profile", {
                          state: {
                            ...state,
                            fullname: item.firstname + " " + item.lastname,
                          },
                        })
                      }
                    >
                      View Profile
                    </button>
                  </div>
                ))}
              <div className="flex justify-center">
                <button
                  className="bg-white text-gray-700 p-1 rounded-2xl shadow w-32 border border-gray-400"
                  onClick={() =>
                    navigate("/user-list", {
                      state,
                    })
                  }
                >
                  All Users
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className=" ml-80 mr-80 mb-8">
          <div>
            <h3 className="text-2xl font-semibold mb-4">Subscriptions</h3>
            <div className="bg-white rounded-2xl shadow-lg p-6">
              <div className="flex items-center mb-4">
                <span className="flex-grow">6D Training for Teams</span>
                <span className="bg-green-500 w-4 h-4 rounded-full"></span>
              </div>
              <div className="flex items-center mb-4">
                <span className="flex-grow">6D Specialist Team Training</span>
                <span className="bg-green-500 w-4 h-4 rounded-full"></span>
              </div>
              <div className="flex items-center mb-4">
                <span className="flex-grow">6D Advanced Team Training</span>
                <span className="bg-red-500 w-4 h-4 rounded-full"></span>
              </div>
              <div className="flex justify-center">
                <button
                  className="bg-orange-500 text-white p-1 rounded-2xl w-32"
                  onClick={() =>
                    navigate("/subscriptions", {
                      state,
                    })
                  }
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>

        <FooterComponent path="/customers" />
      </div>
    </div>
  );
};

export default OrganizationDashboardPage;

import HeaderComponent from "./shared/HeaderComponent";
import dementia from "../../images/strees&behaviour.png";
import behaviouralChange from "../../images/changes_in_behaviour.png";
import stressAndBehaviour from "../../images/dementia.png";
import play from "../../images/play_button.jpeg";
import React from "react";
import FooterComponent from "./shared/FooterComponent";
import { useNavigate } from "react-router-dom";
import VideoModal from "./shared/VideoModalComponent";
import { useState } from "react";
import localVideo from "../../videos/sample.mp4";
// import localVideo from "../../videos/.mp4"; // Update the path to your local video

const SupportingMaterialsPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    // link.href = 'https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me%20Ideas.pdf';
    link.href =
      "https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me.pdf";

    link.download = "file.pdf"; // This will be the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const favouriteModules = [
    {
      title: "What is Dementia",
      image: dementia,
      button: "View",
    },
    {
      title: "Changes in Behaviour",
      image: behaviouralChange,
      button: "View",
      videoUrl:
        "https://www.youtube.com/watch?v=ajGo94h0JxE&list=RDajGo94h0JxE",
    },
    {
      title: "Stress and Behaviour",
      image: stressAndBehaviour,
      button: "View",
      videoUrl:
        "https://www.youtube.com/watch?v=ajGo94h0JxE&list=RDajGo94h0JxE",
    },
  ];

  const researchPapers = [
    {
      title: "Dementia Stress and Behaviour",
      author: "Fred Bloggs",
      date: "15/02/2023",
      button: "Download",
    },
    {
      title: "Psychological well-being in Dementia",
      author: "John Jones",
      date: "03/04/1999",
      button: "Download",
    },
  ];

  const handbooks = [
    "Company Guidelines",
    "Risk Management",
    "Company Handbook",
  ];

  const assessments = [
    { title: "Care Staff Training Assessment 1", button: "Start Assessment" },
    {
      title: "Dementia Team Training Assessment 2",
      button: "Start Assessment",
    },
  ];

  const pdfDocuments = [
    { title: "Training Introduction", button: "Download" },
    { title: "Training Objectives", button: "Download" },
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4 mt-[-5%]">
          <div className="flex justify-end">
            <button
              className="bg-yellow-500 text-white py-2 px-8 rounded-lg"
              onClick={() => navigate("/upload-supporting-materials")}
            >
              Upload Supporting Material
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                Favourite Modules
              </h2>
              <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                {favouriteModules.map((module, index) => (
                  <div key={index} className="flex">
                    <img
                      src={module.image}
                      alt={module.title}
                      className="h-24 w-32 object-cover rounded-lg mb-4"
                    />
                    <div className="ml-4 mt-2">
                      <h3 className="font-semibold mb-2">{module.title}</h3>
                      <div className="flex justify-center mt-2">
                        <img
                          src={play}
                          alt={module.title}
                          className="w-8 h-8 cursor-pointer"
                          onClick={() => navigate("/mediaplayer")}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <button className="bg-customBlue text-white py-2 px-4 rounded-lg w-full mt-5">
                  View
                </button>
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                Research Papers
              </h2>
              {researchPapers.map((paper, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-4 mb-4"
                >
                  <h3 className="flex justify-center text-xl font-semibold mb-2">
                    {paper.title}
                  </h3>
                  <p className="text-gray-700">Author: {paper.author}</p>
                  <p className="text-gray-700 mb-4">
                    Publication Date: {paper.date}
                  </p>
                  <button
                    className="bg-customBlue text-white py-2 px-4 rounded-lg w-full"
                    onClick={handleDownload}
                  >
                    {paper.button}
                  </button>
                </div>
              ))}
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                6D Handbooks
              </h2>
              <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                {handbooks.map((handbook, index) => (
                  <button
                    key={index}
                    className="bg-gray-300 text-black py-4 px-4 rounded-lg w-full mt-4"
                  >
                    {handbook}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                Assessments
              </h2>
              {assessments.map((assessment, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-4 mb-4"
                >
                  <p className="mb-2">{assessment.title}</p>
                  <button className="bg-customBlue text-white py-2 px-4 rounded-lg w-full">
                    {assessment.button}
                  </button>
                </div>
              ))}
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                PDF Documents
              </h2>
              {pdfDocuments.map((pdf, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-4 mb-4"
                >
                  <h4 className="flex justify-center text-xl font-semibold mb-2">
                    {pdf.title}
                  </h4>
                  <button
                    className="bg-customBlue text-white py-2 px-4 rounded-lg w-full"
                    onClick={handleDownload}
                  >
                    {pdf.button}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <FooterComponent path="/homescreen" />
        </div>
      </main>
      <VideoModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        videoUrl={localVideo}
      />
    </div>
  );
};

export default SupportingMaterialsPage;
